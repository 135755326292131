import React from "react";

import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";

export default [
  {
    img: img1,
    content: (
      <p>
        I really hope that you all enjoy my page!  I've worked really hard on it
        and I hope that I can receive feedback from it that will help me grow as 
        a developer.  I am in my 4th year at Saint Joe's and I plan to graduate by the
        end of Spring 2021!  My field of study is Information Technology, but I have taken
        various computer science courses which pretty much has taken me all the way through
        the computer science core requirements at Saint Joe's.  These are the computer science
        courses that I have taken while attending Saint Joseph's in Philly: Computer Science I,
         and II, Discrete Structures I and II, Data Structures, Computer Architecture, Computer Systems,
          Database Management Systems, The Internet of Things, Internet Application Development, Python The Joy of Computing,
           and Design & Analysis Algorithms.  Obviously I have been able to handle some tough classes,
           so if you have any ideas anywhere from App development to Algorithms please feel free to reach out to me!
      </p>
    )
  },
  {
    img: img2,
    content: (
      <p>
        I started learning about react 3 years ago when I took my
        first tutorial on the jsx.  Since then I have been exploring
        various plugins, specifically gatsby plugins.  This is my first
        website that is written in React and I plan on challenging myself
        so that I can become a better developer.  Please follow any of my
        social media accounts in my Contact Me page in order to reach out to 
        me concerning any work that you might think I can accomplish.
      </p>
    )
  },
  {
    img: img3,
    content: (
      <p>
        This is one of my favorite hobbies to do, playing the drums.
        I am a member of the Saint Joseph's University Pep Band in which
        I play the bass drum.  I have played percussion since the 4th grade,
        and I continued my percussion career throughout high school, at Salesianum,
        and of course at Saint Joe's, THWND!
      </p>
    )
  }
];