import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ImageCarousel from "../components/carousel/image-carousel"

export default function About({ data }) {
  return (
    <>
      <Layout invert />
      <ImageCarousel />
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
